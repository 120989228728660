//
//
//
//
//
//
import Analytics from './Analytics.vue';
export default {
  name: 'AppsPeopleCounting',
  components: {
    Analytics: Analytics
  },
  data: function data() {
    return {
      t: 'analytics'
    };
  }
};