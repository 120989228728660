<template>
  <div>
    <SettingModal ref="SettingModal" @update="onSettingData" />

    <StatFilter ref="StatFilter" :PERIOD="cycle" @query="onQuery">
      <CCardHeader slot="header">
        <CRow class="justify-content-between px-2">
          <span>
            <CButton color="light" @click="onShowFilter"><CIcon name="expand" /></CButton>
            <CButton class="ml-2" color="light" @click="onResetFilter"><CIcon name="refresh" />{{$t('cloud.title.filter_reset')}}</CButton>
          </span>
          <CDropdown color="light">
            <span slot="toggler-content"><CIcon class="mr-1" name="cil-settings" size="sm" />{{$t('cloud.title.setting')}}</span>
            <CDropdownItem @click="onSettingOpen"><CIcon class="mr-2" name="cil-bar-chart" />{{$t('cloud.title.view_setting')}}</CDropdownItem>
            <CDropdownItem @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CDropdownItem>
          </CDropdown>
          <!-- <CButton color="light" @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CButton> -->
        </CRow>
      </CCardHeader>
    </StatFilter>

    <CRow v-if="load_done">
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.total')" :text="`${stats.today_people}`">
          <h5 :class="{'text-success':todayPeopleIncrease >= 0,'text-danger':todayPeopleIncrease<0}">
            <svgicon :name="todayPeopleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayPeopleIncrease*todayPeopleIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t(`cloud.title.people_average_${cycle==='daily'?'hour':'day'}`)" :text="`${stats.people_avg}`">
          <h5 :class="{'text-success':avgPeopleIncrease >= 0,'text-danger':avgPeopleIncrease<0}">
            <svgicon :name="avgPeopleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(avgPeopleIncrease*avgPeopleIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.visitor_busiest_time')" :text="stats.busiest_hour">
          <h5 class="text-dark">
            ({{$t($resource.COMPARE_STR_KEY[cycle])}}) {{stats.busiest_hour_yesterday}}
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.event_count')" :text="stats.event_count || '0'">
          <h5 class="text-dark">
            ({{$t($resource.COMPARE_STR_KEY[cycle])}}) {{stats.event_count}}
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol xl="12" xxl="6">
        <CardBarCharts
          :title="$t('cloud.title.people_count')"
          :stats="[stats.people_count, stats.people_count_yesterday]"
          :labels="[$t($resource.TARGET_STR_KEY[cycle]), $t($resource.COMPARE_STR_KEY[cycle])]"
          :colors="['#2982cc', '#80c6ff']"
        />
      </CCol>
      <CCol xl="12" xxl="6">
        <CardLineCharts
          :title="$t('cloud.title.people_count')"
          :stats="[stats.people_count, stats.people_count_yesterday]"
          :labels="[$t($resource.TARGET_STR_KEY[cycle]), $t($resource.COMPARE_STR_KEY[cycle])]"
          :colors="['#2982cc', '#80c6ff']"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardBarCharts from '@/components/cards/BarCharts.vue'
import CardLineCharts from '@/components/cards/LineCharts.vue'
import SettingModal from '@/components/modals/SettingModal.vue'

export default {
  name: 'RetailVisitCountingAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardBarCharts,
    CardLineCharts,
    SettingModal
  },

  computed: {
    ...mapState([
      'capability'
    ]),
    todayPeopleIncrease() {
      return (this.stats.today_people - this.stats.today_people_yesterday);
    },
    avgPeopleIncrease() {
      return (this.stats.people_avg - this.stats.people_avg_yesterday);
    },
  },
  data() {
    return {
      load_done: false,
      loading: {
        counts: true
      },
      cycle: 'daily',
      filters: null,
      stats: {
        people_count: null,
        people_count_yesterday: null,
        today_people: 0,
        today_people_yesterday: 0,
        people_avg: 0,
        people_avg_yesterday: 0,
        busiest_hour: '',
        busiest_hour_yesterday: '',
        event_count: 0
      }
    }
  },
  methods: {
    onQuery(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.statPeopleCount();
        this.statBusiestHour();
      }
    },
    buildQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.device = this.filters.device;
        params.counter = this.filters.counter;
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[0]);
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[1]);
        params.interval = this.filters.unit;
      }
      let from_ts = this.filters.dates[0].getTime();
      let to_ts = this.filters.dates[1].getTime();
      for (let i=0; i<date_before; i++) {
        let temp_t_to = from_ts;
        let temp_t_from = temp_t_to - (to_ts-from_ts);
        from_ts = temp_t_from;
        to_ts = temp_t_to;
      }
      if (date_before) {
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(from_ts));
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(to_ts));
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statPeopleCount() {
      const query_string = this.buildQs();
      const query_string_yesterday = this.buildQs(1);
      let loading_today = true;
      let loading_yesterday = true;

      this.loading.counts = true;
      axios.get(`/api/counter/people_counting/stat/${query_string}`)
        .then(result => {
          this.stats.people_count = result.data.map(el => {
            const val = el.delta_sum;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
          // const now = new Date();
          let item_cnt = result.data.length;
          let max_val = 0;
          let max_at = this.stats.people_count[0].x;
          this.stats.today_people = 0;
          for (const c of this.stats.people_count) {
            this.stats.today_people += c.value;
            // if (new Date(c.x).getTime() < now.getTime()) {
            //   item_cnt++;
            // }
            if (max_val < c.value) {
              max_val = c.value;
              max_at = c.x;
            }
          }
          let cnt_0 = 0;
          let cnt_0_r = 0;
          for (let i=0; i<item_cnt; i++) {
            if (this.stats.people_count[i].value === 0) {
              cnt_0++;
              continue;
            }
            break;
          }
          for (let i=item_cnt-1; i>=0; i--) {
            if (this.stats.people_count[i].value === 0) {
              cnt_0_r++;
              continue;
            }
            break;
          }
          let cont_non0_cnt = (item_cnt-cnt_0-cnt_0_r);
          if (cont_non0_cnt <= 0) {
            this.stats.people_avg = 0;
          } else {
            this.stats.people_avg = Math.round(this.stats.today_people / cont_non0_cnt);
          }
          let d = new Date(max_at);
          if (this.cycle === 'daily') {
            this.stats.busiest_hour = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'HH:00');
          } else if (this.cycle === 'weekly') {
            this.stats.busiest_hour = this.$t(`cloud.title.dow_${this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'EEE').toLowerCase()}2`);
          } else if (this.cycle === 'monthly') {
            this.stats.busiest_hour = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'MM/dd');
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.counts = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/counter/people_counting/stat/${query_string_yesterday}`)
        .then(result => {
          this.stats.people_count_yesterday = result.data.map(el => {
            const val = el.delta_sum;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });

          let item_cnt = result.data.length;
          let max_val = 0;
          let max_at = this.stats.people_count_yesterday[0].x;
          this.stats.today_people_yesterday = 0;
          for (const c of this.stats.people_count_yesterday) {
            this.stats.today_people_yesterday += c.value;
            if (max_val < c.value) {
              max_val = c.value;
              max_at = c.x;
            }
          }
          let cnt_0 = 0;
          let cnt_0_r = 0;
          for (let i=0; i<item_cnt; i++) {
            if (this.stats.people_count[i].value === 0) {
              cnt_0++;
              continue;
            }
            break;
          }
          for (let i=item_cnt-1; i>=0; i--) {
            if (this.stats.people_count[i].value === 0) {
              cnt_0_r++;
              continue;
            }
            break;
          }
          let cont_non0_cnt = (item_cnt-cnt_0-cnt_0_r);
          if (cont_non0_cnt <= 0) {
            this.stats.people_avg_yesterday = 0;
          } else {
            this.stats.people_avg_yesterday = Math.round(this.stats.today_people_yesterday / cont_non0_cnt);
          }
          let d = new Date(max_at);
          if (this.cycle === 'daily') {
            this.stats.busiest_hour_yesterday = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'HH:00');
          } else if (this.cycle === 'weekly') {
            this.stats.busiest_hour_yesterday = this.$t(`cloud.title.dow_${this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'EEE').toLowerCase()}2`);
          } else if (this.cycle === 'monthly') {
            this.stats.busiest_hour_yesterday = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'MM/dd');
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.counts = false;
            this.checkLoading();
          }
        })
    },
    statBusiestHour() {
      
    },
    checkLoading() {
      if (!this.loading.counts) {
        setTimeout(() => {
          this.load_done = true;
        }, 1000);
      }
    },




    // 내보내기
    onExportOpen() {
      let param = {
        stats: this.stats,
        cycle: this.cycle
      };
      let param_str = JSON.stringify(param);
      let param_encoded = window.btoa(unescape(encodeURIComponent(param_str)));
      this.$router.push(`/report/apps/people_counting?p=${param_encoded}`);
    },
    onSettingOpen() {
      this.$refs.SettingModal.open();
    },
    onSettingData() {
      const record = this.$refs.SettingModal.getData();
      this.cycle = record.cycle;
      setTimeout(() => {
        this.$refs.StatFilter.onQuery();
      }, 1);
    },
    onResetFilter() {
      this.$refs.StatFilter.onReset();
    },
    onShowFilter() {
      this.$refs.StatFilter.showFilter();
    }
  }
}
</script>