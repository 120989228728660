<template>
  <div>
    <CCard>
      <slot name="header" />
      <CCollapse :show="show_filter">
        <CCardBody>
          <CRow>
            <CCol md="12" lg="10" v-if="filters.date">
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.time_range')}}</div>
                <el-button plain icon="el-icon-arrow-left" class="px-1" style="border:1px solid #fff;" @click="onPrev"></el-button>
                <el-date-picker v-if="PERIOD === 'daily'" class="my-1 filter_input_size" v-model="filters.date" type="date" :clearable="false" @change="onQuery" />
                <el-date-picker v-else-if="PERIOD === 'weekly'" class="my-1 filter_input_size" v-model="filters.date" type="week" :format="$t('cloud.title.time_fmt_weekly')" :clearable="false"  @change="onQuery" />
                <el-date-picker v-else-if="PERIOD === 'monthly'" class="my-1 filter_input_size" v-model="filters.date" type="month" :format="$t('cloud.title.time_fmt_monthly')" :clearable="false"  @change="onQuery" />
                <el-date-picker
                  v-else-if="PERIOD === 'custom'"
                  class="my-1"
                  v-model="filters.dates"
                  type="datetimerange"
                  range-separator="-"
                  :start-placeholder="$t('cloud.title.range_start_date')"
                  :end-placeholder="$t('cloud.title.range_end_date')"
                  :default-time="['00:00:00', '23:59:59']"
                  @change="onQuery"
                />
                <el-button plain icon="el-icon-arrow-right" class="px-1" style="border:1px solid #fff;" @click="onNext"></el-button>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.target_data')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filters.branch" :placeholder="$t('cloud.title.all_branch')" filterable clearable @change="onChangeBranch" @clear="onQuery">
                  <el-option-group
                    v-for="group in regions"
                    :key="`regions-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`branch-${option.id}`"
                      :label="option.name"
                      :value="option.id">
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-select class="ml-1 my-1 filter_input_size" v-model="filters.device" :placeholder="$t('cloud.title.all_device')" filterable clearable @change="onChangeDevice" @clear="onQuery">
                  <el-option
                    v-for="option in filteredDevices"
                    :key="`device-${option.id}`"
                    :label="option.name"
                    :value="option.device_id">
                  </el-option>
                </el-select>
                <el-select class="ml-1 my-1 filter_input_size" v-model="filters.counter" :placeholder="$t('cloud.title.all_counter')" filterable clearable @change="onQuery" @clear="onQuery">
                  <el-option
                    v-for="option in filteredCounters"
                    :key="`counter-${option.sid}`"
                    :label="`${option.note?option.name+' ('+option.note+')':option.name}`"
                    :value="option.sid">
                  </el-option>
                </el-select>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CompPeopleCountingStatFilter',
  props: {
    PERIOD: {type: String, default: 'daily'}
  },

  computed: {
    regions() {
      let rtn = [];
      for (const branch of this.branches) {
        let rtn_region = rtn.find(el => el.name === branch.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: branch.region_name,
              id: branch.region,
              options: [
                {name: branch.name, id: branch.sid}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: branch.name, id: branch.sid});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    },
    filteredDevices() {
      let rtn = [];
      for (const device of this.devices) {
        if (this.filters.branch && this.filters.branch !== device.branch_sid) continue;
        rtn.push(device);
      }
      return rtn;
    },
    filteredCounters() {
      let rtn = [];
      for (const counter of this.counters) {
        if (this.filters.branch && this.filters.branch !== counter.branch_sid) continue;
        if (this.filters.device && this.filters.device !== counter.device_id) continue;
        rtn.push(counter);
      }
      return rtn;
    }
  },
  data() {
    return {
      show_filter: true,

      filters: {
        dates: null,
        date: null,
        branch: null,
        device: null,
        counter: null,
        unit: null
      },

      branches: [],
      devices: [],
      counters: [],

      payload: {}
    }
  },
  async mounted() {
    await this.getInitData();
    this.onReset();
  },
  methods: {
    async getInitData() {
      let res_branch = await axios.get(`/api/branches/?ipp=10000`);
      this.branches = this.$utility.copy(res_branch.data.list);

      let res_bridge = await axios.get(`/api/bridge/?ipp=10000`);
      this.devices = this.$utility.copy(res_bridge.data.list);

      let res_counter = await axios.get(`/api/counter/people_counting/?ipp=10000`);
      this.counters = this.$utility.copy(res_counter.data.list);
    },
    onQuery() {
      this.setDates();
      this.$emit('query', this.filters);
    },
    onReset() {
      this.filters.date = new Date();
      this.filters.branch = null;
      this.filters.counter = null;
      this.onQuery();
    },
    onPrev() {
      let now_ts = this.filters.date.getTime();
      let day_ts = 24*60*60*1000;
      let change_ts = now_ts;
      if (this.PERIOD === 'daily') {
        change_ts -= day_ts
      } else if (this.PERIOD === 'weekly') {
        change_ts -= (7*day_ts);
      } else if (this.PERIOD === 'monthly') {
        let date = this.filters.date.getDate();
        change_ts -= ((date+1)*day_ts);
      } else {
        return;
      }
      this.filters.date = new Date(change_ts);
      this.onQuery();
    },
    onNext() {
      let now_ts = this.filters.date.getTime();
      let day_ts = 24*60*60*1000;
      let change_ts = now_ts;
      if (this.PERIOD === 'daily') {
        change_ts += day_ts
      } else if (this.PERIOD === 'weekly') {
        change_ts += (7*day_ts);
      } else if (this.PERIOD === 'monthly') {
        let date = this.filters.date.getDate();
        change_ts += ((date+1)*day_ts);
      } else {
        return;
      }
      this.filters.date = new Date(change_ts);
      this.onQuery();
    },
    showFilter() {
      this.show_filter = !this.show_filter;
    },
    setDates() {
      // this.filters.date 를 설정하고 호출하면 설정 주기에 따라서 시간 범위를 만들어 낸다.
      this.filters.dates = [new Date(this.filters.date.getTime()), new Date(this.filters.date.getTime())];
      if (this.PERIOD === 'daily') {
        this.filters.dates[1].setDate(this.filters.date.getDate() + 1);
      } else if (this.PERIOD === 'weekly') {
        let day = this.filters.date.getDay();
        this.filters.dates[0].setDate(this.filters.date.getDate() - day);
        this.filters.dates[1].setDate(this.filters.date.getDate() + (7-day));
      } else if (this.PERIOD === 'monthly') {
        this.filters.dates[0].setDate(1);
        this.filters.dates[1].setDate(1);
        this.filters.dates[1].setMonth(this.filters.dates[1].getMonth()+1);
      }
      this.filters.dates[0].setHours(0);
      this.filters.dates[0].setMinutes(0);
      this.filters.dates[0].setSeconds(0);
      this.filters.dates[0].setMilliseconds(0);
      this.filters.dates[1].setHours(0);
      this.filters.dates[1].setMinutes(0);
      this.filters.dates[1].setSeconds(0);
      this.filters.dates[1].setMilliseconds(0);

      if (this.filters.dates[1].getTime() - this.filters.dates[0].getTime() > 24*60*60*1000) {
        this.filters.unit = 24 * 3600;
      } else {
        this.filters.unit = 1 * 3600;
      }
    },
    onChangeBranch() {
      this.filters.device = null;
      this.filters.counter = null;
      this.onQuery();
    },
    onChangeDevice() {
      this.filters.counter = null;
      this.onQuery();
    },
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>
